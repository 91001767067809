<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="elevatorLcds">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('elevatorLcd.onLineStatus')">
          <el-select v-model.trim="search.onLineStatus" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option v-for="item in onLineStateOptions" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('deviceGateway.type')">
          <el-select v-model.trim="search.type" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button v-if="editAuth" class="addBtn" @click="$refs.editPage.open(0)">
          {{$t("common.add")}}
        </el-button>
      </template>
      <table-column prop="elevatorNo" :label="$t('elevatorLcd.elevatorNo')" :width="120"
                       align="center"></table-column>
      <table-column prop="elevatorName" show-overflow-tooltip
                       :label="$t('elevatorLcd.elevatorName')"></table-column>
      <table-column prop="useUnitName" show-overflow-tooltip
                       :label="$t('elevatorLcd.useUnitName')"></table-column>
      <table-column prop="terminalID" :label="$t('elevatorLcd.terminalID')"></table-column>
      <table-column prop="lastHeartTime" :label="$t('elevatorLcd.heartTime')" align="center"></table-column>
      <table-column prop="type" :label="$t('deviceGateway.type')" :width="120" align="center">
        <template #default="scope">
          <span v-if="scope.row.type === 1">
            英旭
          </span>
          <span v-if="scope.row.type === 2">智显</span>
        </template>
      </table-column>
      <table-column prop="onlineStatus" :label="$t('elevatorLcd.onLineStatus')" :width="100" align="center">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.onlineStatus === 1">
            {{$t("elevatorLcd.online")}}
          </el-tag>
          <el-tag type="info" v-else>{{$t("elevatorLcd.offline")}}</el-tag>
        </template>
      </table-column>
      <table-column prop="cardName" :label="$t('elevatorLcd.currentProgram')"
                       show-overflow-tooltip align="center"></table-column>
      <table-column v-if="editAuth" :label="$t('common.operation')" align="center" :width="250" :tooltip="false">
        <template #default="scope">
          <!--                    <el-button :disabled="!scope.row.elevatorId" @click="lcdCall(scope.row)">-->
          <!--                      {{$l("common.edit", "呼叫")}}-->
          <!--                    </el-button>-->
          <!--                    <el-button-->
          <!--                      :disabled="!scope.row.elevatorId"-->
          <!--                      @click="start(scope.row)">-->
          <!--                      {{$l("common.edit", "接听")}}-->
          <!--                    </el-button>-->
          <el-button v-if="scope.row.terminalID" type="text" @click="$refs.logs.open(scope.row.terminalID)">
            {{ $t("device.record") }}
          </el-button>
          <el-button :disabled="!scope.row.programCardId" size="mini" type="text"
                     @click="$refs.programCardPreview.open(scope.row.programCardId)">
            {{$t("elevatorLcd.previewProgram")}}
          </el-button>
          <el-button type="text" @click="handleEdit(scope.row)">{{$t("common.edit")}}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{
              $t("common.delete")
            }}
          </el-button>
        </template>
      </table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(-1)"></edit-dialog>
    <program-card-preview ref="programCardPreview"></program-card-preview>
    <web-r-t-c ref="rtc"></web-r-t-c>
    <elevator-lcd-log-list ref="logs"></elevator-lcd-log-list>
  </div>
</template>
<script>
  import EditDialog from "./ElevatorLcdEdit.vue";
  import ProgramCardPreview from "@/views/smartScreen/ProgramCardPreview";
  import WebRTC from "./WebRTC";
  import ElevatorLcdLogList from "./ElevatorLcdLogList";

  const moduleName = "elevatorLcds";

  export default {
    components: {ElevatorLcdLogList,EditDialog, ProgramCardPreview, WebRTC},
    data() {
      return {
        editAuth: this.$auth(74),
        loading: false,
        search: {
          isBind: 1,
          filter: "",
          type: "",
          onLineStatus: "",
        },
        onLineStateOptions: [
          {value: 0, label: this.$t("elevatorLcd.offline")},
          {value: 1, label: this.$t("elevatorLcd.online")},
        ],
        typeOptions: [
          {value: 1, label: "英旭"},
          {value: 2, label: "智显"},
        ],
      };
    },
    mounted() {
      this.getList(1);
      // setInterval(() => this.getList(1), 10000);
    },
    methods: {
      start(row) {
        this.$refs.rtc.open(row);
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("elevatorLcd.terminalID") + " " + row.terminalID + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById(moduleName, row.id).then(res => {
            this.getList(1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
