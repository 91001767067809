<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="programCardList.id? $t('programCard.edit'):$t('programCard.add')"
    :visible.sync="dialogVisible"
    class="small-padding"
    top="2vh"
    width="1100px"
    @close="$reset('form')">
    <div style="display: flex">
      <div style="flex:1; margin-right: 20px; ">
        <el-form
          ref="form"
          :model="programCardList"
          class="form"
          :label-width="$l('110px','80px')">
          <el-form-item :label="$t('programCard.programCardName')" prop="cardName" :rules="$rule.notNull">
            <el-input v-model="programCardList.cardName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('programCard.notice')" prop="notice">
            <el-input
              v-model="programCardList.notice"
              :placeholder="$t('common.pleaseEnter')"
              rows="3"
              type="textarea"></el-input>
          </el-form-item>
            <div style="margin:30px 0 0 20px;font-size: 14px;color: red;line-height:22px;">
              <div>{{ $t('programCard.prompt1') }}</div>
              <div>{{ $t('programCard.prompt2') }}</div>
              <div>{{ $t('programCard.prompt3') }}</div>
              <div>{{ $t('programCard.prompt4') }}</div>
            </div>
        </el-form>
      </div>
      <div style="width: 400px">
        <el-upload
          :action="uploadUrl"
          :before-upload="beforeUpload"
          :on-error="handlerError"
          :on-success="handleSuccess"
          :data="programData"
          :multiple="true"
          :show-file-list="false"
          accept=".mp4, .png, .jpeg, .jpg, .gif"
          style="display: inline-block;margin-right: 40px;">
          <el-button
            :loading="uploadLoading"
            icon="el-icon-upload2"
            size="mini"
            type="primary">
            {{$t("programCard.upload")}}
          </el-button>
        </el-upload>
        <el-table :data="programCardList.programList" size="mini" style="width: 100%;margin-top: 10px" border>
          <table-column :label="$t('programCard.program')" prop="type" align="center">
            <template #default="scope">
              <img
                v-if="scope.row.attachmentPath.substring(scope.row.attachmentPath.lastIndexOf('.'), scope.row.attachmentPath.length).toLowerCase() === '.jpg' ||
                  scope.row.attachmentPath.substring(scope.row.attachmentPath.lastIndexOf('.'), scope.row.attachmentPath.length).toLowerCase() === '.jpeg' ||
                  scope.row.attachmentPath.substring(scope.row.attachmentPath.lastIndexOf('.'), scope.row.attachmentPath.length).toLowerCase() === '.png' ||
                  scope.row.attachmentPath.substring(scope.row.attachmentPath.lastIndexOf('.'), scope.row.attachmentPath.length).toLowerCase() === '.gif'"
                :src="scope.row.attachmentPath"
                width="200px"/>
              <video
                v-if="scope.row.attachmentPath.substring(scope.row.attachmentPath.lastIndexOf('.'), scope.row.attachmentPath.length).toLowerCase() === '.mp4'"
                controls
                width="200px">
                <source :src="scope.row.attachmentPath" type="video/mp4"/>
                {{$t("programCard.notSupportVideo")}}
              </video>
            </template>
          </table-column>
          <table-column :label="$t('common.operation')" :width="$l('82','60')" align="center" :tooltip="false">
            <template #default="scope">
              <el-button
                circle
                icon="el-icon-delete"
                size="mini"
                type="danger"
                @click.native.prevent="handleDelete(scope.$index)"></el-button>
            </template>
          </table-column>
          <table-column :label="$t('programCard.sorting')" :width="80" align="center">
            <template #default="scope">
              <el-button
                size="mini"
                :disabled="scope.$index === 0"
                icon="el-icon-caret-top"
                @click="handleUp(scope.$index)"></el-button>
              <br/>
              <el-button
                size="mini"
                :disabled="scope.$index === programCardList.programList.length - 1"
                icon="el-icon-caret-bottom"
                style="margin-top: 5px"
                @click="handleDown(scope.$index)"></el-button>
            </template>
          </table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer">
      <el-button size="small" @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        :loading="submitLoading"
        size="small"
        type="primary"
        @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  const url = "programCard";
  export default {
    data() {
      return {
        uploadLoading: false,
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        uploadUrl: window.config.SERVER_URL + "programCard/upload",
        fileUrls: [],
        programCardList: {
          id: 0,
          cardName: "",
          horizontal: 0,
          notice: "",
          programList: [],
        },
        programData: {
          programCardId: 0,
          isImage: false,
        },
      };
    },
    mounted() {
    },
    methods: {
      open(row) {
        console.log(row);
        this.dialogVisible = true;
        if (row !== 0) {
          this.programCardList.id = row.id;
          this.programData.programCardId = row.id;
        } else {
          this.programData.programCardId = 0;
        }
        if (this.programCardList.id > 0) {
          this.getData();
        }
      },
      getData() {
        this.$http.get("programCard/" + this.programCardList.id).then(res => {
          Object.assign(this.programCardList, res.data);
        });
      },
      onDialogClose() {
        this.$refs.form.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save(url, this.programCardList)
              .then((res) => {
                console.log("res.data: " + res.data);
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
      handleDelete(index) {
        this.$confirm(this.$t("common.isDelete"), this.$t("common.tips"), {type: "warning"}).then(() => {
          this.programCardList.programList.splice(index, 1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        });
      },
      beforeUpload(file) {
        let fileName = file.name;
        let pos = fileName.lastIndexOf(".");
        let lastName = fileName.substring(pos, fileName.length);
        let size = file.size / 1024 / 1024;
        if (lastName.toLowerCase() === ".png" ||
          lastName.toLowerCase() === ".jpeg" ||
          lastName.toLowerCase() === ".jpg" ||
          lastName.toLowerCase() === ".gif") {
          if (size > 3) {
            this.$message.error(this.$t("programCard.imageFiles"));
            return false; // 返回false中止上传
          } else {
            this.programData.isImage = true;
          }
        }
        if (lastName.toLowerCase() === ".mp4") {
          if (size > 300) {
            this.$message.error(this.$t("programCard.videoFiles"));
            return false; // 返回false中止上传
          } else {
            this.programData.isImage = false;
          }
        }
        if (lastName.toLowerCase() !== ".png" &&
          lastName.toLowerCase() !== ".jpeg" &&
          lastName.toLowerCase() !== ".jpg" &&
          lastName.toLowerCase() !== ".gif" &&
          lastName.toLowerCase() !== ".mp4"
        ) {
          this.$message.error(this.$t("programCard.fileType"));
          return false; // 返回false中止上传
        }
      },
      handleSuccess(res) {
        console.log(res);
        this.programCardList.programList.push({"attachmentPath": res, "duration": 10});
        this.uploadLoading = false;
        this.$message.success(this.$t("programCard.uploadSuccessful"));
      },
      handlerError(err) {
        this.$message.error(err.message);
        this.uploadLoading = false;
      },
      handleDown(index) {
        let temp = this.programCardList.programList[index];
        this.programCardList.programList.splice(index, 1);
        this.programCardList.programList.splice(index + 1, 0, temp);
      },
      handleUp(index) {
        let temp = this.programCardList.programList[index];
        this.programCardList.programList.splice(index, 1);
        this.programCardList.programList.splice(index - 1, 0, temp);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
