<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="programCard">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.programCardEdit.open(0)">
          {{ $t("common.add") }}
        </el-button>
      </template>
      <table-column prop="cardName" :label="$t('programCard.programCardName')"></table-column>
      <table-column prop="notice" :label="$t('programCard.notice')"></table-column>
      <table-column prop="orgName" :label="$t('programCard.companyName')"></table-column>
      <table-column :label="$t('common.operation')" align="center" :width="350" :tooltip="false">
        <template #default="scope">
          <el-button size="mini" type="text" @click="handlePreview(scope.row.id)">{{ $t("elevatorLcd.previewProgram")
            }}</el-button>
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
            }}</el-button>
        </template>
      </table-column>
    </vm-table>
    <program-card-edit ref="programCardEdit" @save-success="getList(1)"></program-card-edit>
    <program-card-preview ref="programCardPreview"></program-card-preview>
  </div>
</template>

<script>
import ProgramCardEdit from "@/views/smartScreen/ProgramCardEdit";
import ProgramCardPreview from "@/views/smartScreen/ProgramCardPreview";

export default {
  components: { ProgramCardEdit, ProgramCardPreview },
  data() {
    return {
      loading: false,
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },
    handleEdit(row) {
      this.$refs.programCardEdit.open(row);
    },
    handlePreview(id) {
      this.$refs.programCardPreview.open(id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("programCard.programCard") + " " + row.cardName + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById("programCard", row.id).then(res => {
          this.getList(1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
